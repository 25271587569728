import { useState,  useEffect } from "react";

const ThemeToggle = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Retrieve the saved mode from localStorage (use 'true' for dark mode)
        const savedMode = localStorage.getItem('lightDarkMode') === 'true';
        setIsDarkMode(savedMode);

        // Apply the mode to the body class
        const body = document.querySelector('body');
        if (savedMode) {
        body.classList.add('darkmode');
        } else {
        body.classList.remove('darkmode');
        }
    }, []);

    const handleModeChange = (e) => {
        const isChecked = e.target.checked;
    
        // Toggle the dark mode state
        setIsDarkMode(isChecked);
    
        // Save the mode to localStorage (true for dark mode)
        localStorage.setItem('lightDarkMode', isChecked);
    
        // Apply the mode to the body class
        const body = document.querySelector("body");
        if (isChecked) {
        body.classList.add("darkmode");
        } else {
        body.classList.remove("darkmode");
        }
    };

    return (
        <div className="lightDarkModeToggle">
            <input 
                type="checkbox" 
                checked={isDarkMode}
                name="lightDarkModeInput"
                id="lightDarkModeInput"
                onChange={(e) => {handleModeChange(e);}}
            />
            <label htmlFor="lightDarkModeInput">
                <div></div>
            </label>
        </div>
    )
}

export default ThemeToggle