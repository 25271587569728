import { useState, useEffect, lazy, Suspense } from "react";
import Loader from "./components/Loader";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Home = lazy(() => import("./components/Home"));
const Impressum = lazy(() => import("./components/datenschutz/Impressum"));
const Datenschutzerklaerung = lazy(() =>
  import("./components/datenschutz/Datenschutzerklaerung")
);
const ErrorPage = lazy(() => import("./components/ErrorPage"));
const ScrollUpBtn = lazy(() => import("./components/ScrollUpBtn"));

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const minimumLoadingTime = 1500;
    const startTime = performance.now();

    window.onload = () => {
      const loadTime = performance.now() - startTime;
      const remainingTime = minimumLoadingTime - loadTime;

      setTimeout(() => {
        setLoading(false);
      }, Math.max(remainingTime, 0));
    };

    if (document.readyState === "complete") {
      window.onload();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="wrapper">
          <ScrollUpBtn btnWidth={30} btnHeight={30} />
          <Router>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route
                  path="/datenschutz"
                  element={<Datenschutzerklaerung />}
                />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Suspense>
          </Router>
        </div>
      )}
    </>
  );
}

export default App;
