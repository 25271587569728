import ThemeToggle from "./ThemeToggle";

const Loader = () => {
  return (
    <div className="loaderScreen">
        <ThemeToggle />
        <div className="loaderMainContainer">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h2>Loading...</h2>
        </div>
    </div>
  )
}

export default Loader